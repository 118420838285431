<template>
    <v-app :class="rotateClass">
        <router-view />
    </v-app>
</template>
<script>
export default {
    name: 'App',

    watch: {
        $route(to, from) {
            if(to.path != "/" && this.$store.state.rotate){
                this.$store.commit("update_rotate", false)
            }
        }
    },

    computed: {
        rotateClass() {
            return this.$store.state.rotate ? 'rotate' : ''
        }
    },

    created() {
        // 페이지 가시성 변경 이벤트 리스너
        document.addEventListener('visibilitychange', this.handleVisibilityChange)
    },

    beforeDestroy() {
        // 이벤트 리스너 제거
        document.removeEventListener('visibilitychange', this.handleVisibilityChange)
    },

    methods: {
        handleVisibilityChange() {
            if (document.visibilityState === 'visible') {
                // 포그라운드로 돌아왔을 때 실행할 로직
                const lastUpdateTime = localStorage.getItem('lastListUpdateTime')
                const currentTime = new Date().getTime()
                
                // 마지막 업데이트로부터 1시간이 지났는지 확인
                if (lastUpdateTime && (currentTime - lastUpdateTime) >= 10000) {
                    this.$store.commit('reset_list')
                }
            }
        }
    }
}
</script>
<style>
.rotate {
    transform: rotate(180deg);
}
</style>
<style>
/* Chrome, Safari, Edge 전용 */
::-webkit-scrollbar {
    width: 16px; /* 스크롤바 너비 */
    border-radius: 10px; /* 스크롤바 모서리 둥글게 */
}

::-webkit-scrollbar-track {
    background: #f5f5f5; /* 스크롤바 배경 */
    width: 16px;
    border-radius: 10px; /* 스크롤바 모서리 둥글게 */
}

::-webkit-scrollbar-thumb {
    background-color: #ACACAC; /* 스크롤바 색상 */
    border-radius: 10px; /* 스크롤바 모서리 둥글게 */
    width:8px !important;
    min-height:40px !important;
    border: 4px solid #f5f5f5; /* 스크롤바 막대 테두리 설정  */
}

/* ::-webkit-scrollbar-thumb:hover, */
::-webkit-scrollbar-thumb:active {
    background-color: #0369a1 !important; /* 스크롤바 호버 색상 */
}
</style>