import Decimal from 'decimal.js'

const methods = {
    // # 메인 결과값
    result_main(list, sub_tab){
        // 객체계산식을 문자열계산식으로 변환
        let value = this.$list_to_evaluated_number(list)

        // $Store에 저장
        if(list.length >= 1){
            this.$store.state.result = value
        }

        // 숫자가 1개일 경우 표시하지 않음
        if(list.filter(item => item.number !== null).length <= 1){
            return ''
        }

        // 0일 경우 바로 0으로 리턴
        else if(value == 0){
            return "0"
        }

        // NaN, Infinity 등... 일 경우 "유효하지 않은 값" 문구로 리턴
        else if(value.e !== undefined && isNaN(value.e) && !isFinite(value.e)){
            return this.$store.state.language == '한국어' ? '유효하지 않은 값' : 'Invalid value'
        }

        // 서브탭이 000모드가 아닐 경우
        else if(sub_tab != "000"){
            if(this.$store.state.summary_amount != 100){
                console.log(value)
                const magnitude = Math.floor(Math.log10(Math.abs(value.toNumber())))
                const exponent = Math.max(0, magnitude - this.$store.state.summary_amount)
                value = this.$math.bignumber(Math.round(value.toNumber() / (10 ** exponent)) * (10 ** exponent))

                console.log(value)
                return this.$number_to_scaled_number(value, sub_tab)
            }
            else{
                // 서브탭에 따라 숫자 -> 숫자 + 단위로 리턴
                return this.$number_to_scaled_number(value, sub_tab)
            }
        }

        // 서브탭이 000모드일 경우
        else{
            // 콤마 추가, 최대 소수점 설정값 반영
            return this.$math.number(value).toLocaleString('en', {maximumFractionDigits: this.$store.state.sosujum})
        }
    },


    // # 서브 결과값
    result_sub(list, sub_tab, input_tab){
        // 객체계산식을 문자열계산식으로 변환
        let value = this.$list_to_evaluated_number(list)

        // 숫자가 1개일 경우 표시하지 않음
        if(list.filter(item => item.number !== null).length <= 1){
            return ''
        }

        // 0일 경우 표시하지 않음
        else if(value == 0){
            return ''
        }

        // NaN, Infinity 등... 일 경우 표시하지 않음
        else if(value.e !== undefined && isNaN(value.e) && !isFinite(value.e)){
            return ''
        }

        // 서브탭이 000모드일 경우
        else if(sub_tab == "000"){
            // 입력탭이 만억조 모드일 경우 4자리 숫자 이하 표시하지 않기
            if(
                input_tab == "만억조"
                && Math.abs(parseInt(this.$math.number(value))) <= 9999
            ){
                return ''
            }

            // 입력탭이 KMB 모드일 경우 3자리 숫자 이하 표시하지 않기
            else if(
                input_tab == "KMB"
                && Math.abs(parseInt(this.$math.number(value))) <= 999
            ){
                return ''
            }

            // 그 외 숫자 -> 입력탭에 따라 숫자 + 단위로 변환
            else{
                if(this.$store.state.summary_amount != 100){
                    const magnitude = Math.floor(Math.log10(Math.abs(value.toNumber())))
                    const exponent = Math.max(0, magnitude - this.$store.state.summary_amount)
                    value = this.$math.bignumber(Math.round(value.toNumber() / (10 ** exponent)) * (10 ** exponent))
                    return this.$number_to_scaled_number(value, input_tab)
                }
                else{
                    // 서브탭에 따라 숫자 -> 숫자 + 단위로 리턴
                    return this.$number_to_scaled_number(value, input_tab)
                }
            }
        }
        
        // 서브탭이 만억조 or KMB 모드일 경우
        else{
            // 콤마 추가, 최대 소수점 설정값 반영
            return this.$math.number(value).toLocaleString('en', {maximumFractionDigits: this.$store.state.sosujum})
        }
    },

    
    // # 환전 메인 결과값
    result_exchange_main(list, sub_tab, origin, exchange){
        // 객체계산식을 문자열계산식으로 변환
        let value = this.$list_to_evaluated_number(list)

        // 계산식이 없을 경우 표시하지 않음
        if(list.length == 0){
            return ''
        }

        // 계산식은 있는데 결과값이 0일 경우는 표시
        else if(value.toString() == "0"){
            return 0
        }

        // 계산식이 숫자 1개로 이뤄진 경우
        else if(list.filter(item => item.number !== null).length == 1){
            // 숫자가 % 형식일 경우 그대로 표시
            if(list.at(0).number && list.at(0).number.toString().endsWith('%')){
                return list.at(0).number
            }

            // # 환전 통화의 경우
            if(origin != exchange){
                // 결과값 X (기본 통화 / 환전 통화)
                value = this.$math.multiply(value, this.$math.divide(this.$math.bignumber(origin), this.$math.bignumber(exchange)))

                if(this.$store.state.summary_amount != 100 && sub_tab != '000'){
                    const magnitude = Math.floor(Math.log10(Math.abs(value.toNumber())))
                    const exponent = Math.max(0, magnitude - this.$store.state.summary_amount)
                    value = this.$math.bignumber(Math.round(value.toNumber() / (10 ** exponent)) * (10 ** exponent))
                }
                
                // 표시탭이 만억조 모드일 경우 4자리 숫자 이하 표시하지 않기
                if(
                    sub_tab == "만억조"
                    && Math.abs(parseInt(this.$math.number(value))) <= 9999
                ){
                    // 해당 숫자 그대로 표시
                    return this.$math.number(value).toLocaleString('en', {maximumFractionDigits: this.$store.state.sosujum})
                }

                // 표시탭이 KMB 모드일 경우 3자리 숫자 이하 표시하지 않기
                else if(
                    sub_tab == "KMB"
                    && Math.abs(parseInt(this.$math.number(value))) <= 999
                ){
                    // 해당 숫자 그대로 표시
                    return this.$math.number(value).toLocaleString('en', {maximumFractionDigits: this.$store.state.sosujum})
                }
                
                else{
                    if(sub_tab == '000'){
                        if(list.at(0).scale){
                            // 콤마 추가, 최대 소수점 설정값 반영
                            return this.$math.number(value).toLocaleString('en', {maximumFractionDigits: this.$store.state.sosujum})
                        }else{
                            return this.$math.number(value).toLocaleString('en', {maximumFractionDigits: this.$store.state.sosujum}) 
                        }
                    }

                    else{
                        // 서브탭에 따라 숫자 -> 숫자 + 단위로 리턴
                        return this.$number_to_scaled_number(value, sub_tab)
                    }
                }
            }
            // # 기본 통화의 경우
            else{
                if(this.$store.state.summary_amount != 100){
                    const magnitude = Math.floor(Math.log10(Math.abs(value.toNumber())))
                    const exponent = Math.max(0, magnitude - this.$store.state.summary_amount)
                    value = this.$math.bignumber(Math.round(value.toNumber() / (10 ** exponent)) * (10 ** exponent))
                }

                // 표시탭이 만억조 모드일 경우 4자리 숫자 이하 표시하지 않기
                if(
                    sub_tab == "만억조"
                    && Math.abs(parseInt(this.$math.number(list.at(0).number))) <= 9999
                ){
                    if(list.at(0).number && list.at(0).number.toString().includes('.')){
                        if(list.at(0).scale){
                            if(['만', '억', '조', '경'].includes(list.at(0).scale)){
                                return list.at(0).number + (list.at(0).scale? list.at(0).scale: '' )
                            }
                            else{
                                // 서브탭에 따라 숫자 -> 숫자 + 단위로 리턴
                                return this.$number_to_scaled_number(value, sub_tab)
                            }
                        }else{
                            const [integer, decimal] = list.at(0).number.toString().split('.')
                            return this.$math.number(list.at(0).number).toLocaleString('en', {
                                minimumFractionDigits: decimal.length,
                                maximumFractionDigits: decimal.length
                            })
                        }
                    }else{
                        if(list.at(0).scale){
                            if(['만', '억', '조', '경'].includes(list.at(0).scale)){
                                return this.$math.number(list.at(0).number).toLocaleString('en') + (list.at(0).scale? list.at(0).scale: '' )
                            }
                            else{
                                // 서브탭에 따라 숫자 -> 숫자 + 단위로 리턴
                                return this.$number_to_scaled_number(value, sub_tab)
                            }
                        }else{
                            return this.$math.number(list.at(0).number).toLocaleString('en')
                        }
                    }
                }

                // 표시탭이 KMB 모드일 경우 3자리 숫자 이하 표시하지 않기
                else if(
                    sub_tab == "KMB"
                    && Math.abs(parseInt(this.$math.number(list.at(0).number))) <= 999
                ){
                    if(list.at(0).number && list.at(0).number.toString().includes('.')){
                        if(list.at(0).scale){
                            if(['K', 'M', 'B', 'T'].includes(list.at(0).scale)){
                                return list.at(0).number + (list.at(0).scale? list.at(0).scale: '' )
                            }
                            else{
                                // 서브탭에 따라 숫자 -> 숫자 + 단위로 리턴
                                return this.$number_to_scaled_number(value, sub_tab)
                            }
                        }else{
                            const [integer, decimal] = list.at(0).number.toString().split('.')
                            return this.$math.number(list.at(0).number).toLocaleString('en', {
                                minimumFractionDigits: decimal.length,
                                maximumFractionDigits: decimal.length
                            })
                        }
                    }else{
                        if(list.at(0).scale){
                            if(['K', 'M', 'B', 'T'].includes(list.at(0).scale)){
                                return this.$math.number(list.at(0).number).toLocaleString('en') + (list.at(0).scale? list.at(0).scale: '' )
                            }
                            else{
                                // 서브탭에 따라 숫자 -> 숫자 + 단위로 리턴
                                return this.$number_to_scaled_number(value, sub_tab)
                            }
                        }else{
                            return this.$math.number(list.at(0).number).toLocaleString('en')
                        }
                    }
                }
                
                else if(sub_tab == '000'){
                    if(this.$store.state.summary_amount != 100){
                        const magnitude = Math.floor(Math.log10(Math.abs(value.toNumber())))
                        const exponent = Math.max(0, magnitude - this.$store.state.summary_amount)
                        value = this.$math.bignumber(Math.round(value.toNumber() / (10 ** exponent)) * (10 ** exponent))
                    }
                    
                    if(list.at(0).scale){
                        // 콤마 추가, 최대 소수점 설정값 반영
                        return this.$math.number(value).toLocaleString('en', {maximumFractionDigits: this.$store.state.sosujum})
                    }else{
                        console.log("HI")
                        if(list.at(0).number && list.at(0).number.toString().includes('.')){
                            return list.at(0).number
                        }else{
                            // if(this.$store.state.summary_amount != 100){
                            //     let magnitude2 = Math.floor(Math.log10(parseInt(list.at(0).number)))
                            //     let exponent2 = Math.max(0, magnitude2 - this.$store.state.summary_amount)
                            //     value = this.$math.bignumber(Math.floor(parseInt(list.at(0).number) / (10 ** (exponent2))) * (10 ** (exponent2)))
                        
                            //     return this.$math.number(value).toLocaleString('en') 
                            // }else{
                                return this.$math.number(list.at(0).number).toLocaleString('en') 
                            // }
                        }
                    }
                }

                else{
                    // 서브탭에 따라 숫자 -> 숫자 + 단위로 리턴
                    return this.$number_to_scaled_number(value, sub_tab)
                }
            }
        }

        // NaN, Infinity 등... 일 경우 "유효하지 않은 값" 문구로 리턴
        else if(value.e !== undefined && isNaN(value.e) && !isFinite(value.e)){
            return this.$store.state.language == '한국어' ? '유효하지 않은 값' : 'Invalid value'
        }

        // 정상적인 계산식의 경우
        else{
            // # 환전 통화의 경우
            if(origin != exchange){
                // 결과값 X (기본 통화 / 환전 통화)
                value = this.$math.multiply(value, this.$math.divide(this.$math.bignumber(origin), this.$math.bignumber(exchange)))
            }

            if(this.$store.state.summary_amount != 100){
                const magnitude = Math.floor(Math.log10(Math.abs(value.toNumber())))
                const exponent = Math.max(0, magnitude - this.$store.state.summary_amount)
                value = this.$math.bignumber(Math.round(value.toNumber() / (10 ** exponent)) * (10 ** exponent))
            }
    
            // 서브탭이 000모드가 아닐 경우
            if(sub_tab != "000"){
                // 서브탭에 따라 숫자 -> 숫자 + 단위로 리턴
                return this.$number_to_scaled_number(value, sub_tab)
            }
    
            // 서브탭이 000모드일 경우
            else{
                // 콤마 추가, 최대 소수점 설정값 반영
                return this.$math.number(value).toLocaleString('en', {maximumFractionDigits: this.$store.state.sosujum})
            }
        }
    },

    // # 환전 서브 결과값
    result_exchange_sub(list, show_tab, origin, exchange){
        // 객체계산식을 문자열계산식으로 변환
        let value = this.$list_to_evaluated_number(list)

        // 계산식이 없을 경우 표시하지 않음
        if(list.length == 0){
            return ''
        }

        // NaN, Infinity 등... 일 경우 "유효하지 않은 값" 문구로 리턴
        else if(value.e !== undefined && isNaN(value.e) && !isFinite(value.e)){
            return this.$store.state.language == '한국어' ? '유효하지 않은 값' : 'Invalid value'
        }

        // 정상적인 계산식의 경우
        else{
            // # 환전 통화의 경우
            if(origin != exchange){
                // 결과값 X (기본 통화 / 환전 통화)
                value = this.$math.multiply(value, this.$math.divide(this.$math.bignumber(origin), this.$math.bignumber(exchange)))
            }
    
            // 표시탭이 만억조 모드일 경우 4자리 숫자 이하 표시하지 않기
            if(
                show_tab == "만억조"
                && Math.abs(parseInt(this.$math.number(value))) <= 9999
            ){
                return ''
            }

            // 표시탭이 KMB 모드일 경우 3자리 숫자 이하 표시하지 않기
            else if(
                show_tab == "KMB"
                && Math.abs(parseInt(this.$math.number(value))) <= 999
            ){
                return ''
            }

            // 그 외 숫자 -> 표시탭에 따라 숫자 + 단위로 변환
            else{
                if(this.$store.state.summary_amount != 100 && show_tab != '000'){
                    const magnitude = Math.floor(Math.log10(Math.abs(value.toNumber())))
                    const exponent = Math.max(0, magnitude - this.$store.state.summary_amount)
                    value = this.$math.bignumber(Math.round(value.toNumber() / (10 ** exponent)) * (10 ** exponent))
                }

                // 서브탭에 따라 숫자 -> 숫자 + 단위로 리턴
                return this.$number_to_scaled_number(value, show_tab)
            }
        }
    },
}

export default methods