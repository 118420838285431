const methods = {
    // # 입력칸 콤마
    inputToComma(value) {
        return value = this.$toComma(this.$toUnComma(value))
    },

    // # 콤마 추가
    toComma(value) {
        if(!value) return ''

        let parts = value.toString().split(".")
        parts[0] = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        return parts.join(".")
    },

    // # 콤마 추가 (소수점 자릿수 제한 추가)
    toCommaWithSosujum(value) {
        if(!value) return ''
        
        // 먼저 전체 문자열에 콤마 적용
        let result = this.$toComma(value)
        
        // 공백으로 분리
        let words = result.split(" ")
        
        // 마지막 숫자만 소수점 자릿수 제한 적용
        let lastNum = this.$toUnComma(words[words.length - 1])
        words[words.length - 1] = Number(lastNum).toLocaleString('en', {
            maximumFractionDigits: this.$store.state.sosujum
        })
        
        return words.join(" ")
    },

    // # 콤마 제거
    toUnComma(value) {
        if (!value) return ''

        let parts = value.toString().split(".")
        parts[0] = parts[0].replace(/,/g, '')
        return parts.join(".")
    }
}

export default methods