import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        main_tab: "계산",
        sub_tab: "만억조",
        input_tab: "만억조",
        line: "single",
        edit_mode: false,
        edit_mode2: false,
        disable_key: [')', '/', '*', '+', '00', '%'],
        keyList: [],

        sub_tab_by_header: "만억조",

        rotate: false,
        twin: false,

        screen_mode: "expanded",

        keypad_mode: "expanded",

        result: 0,
        result_save: "",

        list_tab: "통화",

        // 마이페이
        mypage_tab: "계산 설정",

        // 사용자
        client_user: {
            is_logined: false,
            user_id: "",
            nickname: "",
            email: "",
            profile_image: "",
            type: "",
            status: "",
            marketing_kakao : "",
            marketing_sms : "",
            marketing_app : "",
            created: ""
        },

        // 관리자
        client_admin: {
            is_logined: false,
        },

        sosujum: 2,

        list: [],
        exchange_list: [],

        language: "한국어",

        summary_amount: 100,

        little_menu: false,
    },

    getters: {

    },

    mutations: {
        // 메인탭 수정
        update_main_tab: (state, payload) => {
            state.main_tab = payload
        },

        // 서브탭 수정
        update_sub_tab: (state, payload) => {
            state.sub_tab = payload
        },

        // 입력탭 수정
        update_input_tab: (state, payload) => {
            state.input_tab = payload
        },

        // 계산식(저장) 수정
        update_list: (state, payload) => {
            state.list = payload
            localStorage.setItem('lastListUpdateTime', new Date().getTime())
        },

        // 통화 목록(저장) 수정
        update_exchange_list: (state, payload) => {
            state.exchange_list = payload
        },

        // 라인 수정
        update_line: (state, payload) => {
            state.line = payload
        },

        // 수정모드 여부 업데이트
        update_edit_mode: (state, payload) => {
            state.edit_mode = payload
        },

        // 수정모드 여부 업데이트
        update_edit_mode2: (state, payload) => {
            state.edit_mode2 = payload
        },

        // 키 제한 설정
        set_disable_key: (state, payload) => {
            state.disable_key = payload
        },

        // 키 제한 추가
        add_disable_key: (state, payload) => {
            state.disable_key = [...new Set([...state.disable_key, ...payload])]
        },

        // 키 제한 삭제
        remove_disable_key: (state, payload) => {
            state.disable_key = state.disable_key.filter(key => !payload.includes(key))
        },

        // 키 목록 설정
        reset_keyList: (state) => {
            state.keyList = []
        },

        // 키 목록 추가
        push_keyList: (state, payload) => {
            state.keyList.push(payload)
        },

        // 키 목록 삭제
        // pop_keyList: (state) => {
        //     state.keyList.pop()
        // },

        // 사용자 로그인
        login: (state, payload) => {
            state.client_user.is_logined = true
            state.client_user.user_id = payload.user_id
            state.client_user.nickname = payload.nickname
            state.client_user.email = payload.email
            state.client_user.profile_image = payload.profile_image
            state.client_user.type = payload.type
            state.client_user.status = payload.status
            state.client_user.marketing_kakao = payload.marketing_kakao
            state.client_user.marketing_sms = payload.marketing_sms
            state.client_user.marketing_app = payload.marketing_app
            state.client_user.created = payload.created
        },

        // 사용자 로그아웃
        logout: (state) => {
            state.client_user.is_logined = false
            state.client_user.user_id = ""
            state.client_user.nickname = ""
            state.client_user.email = ""
            state.client_user.profile_image = ""
            state.client_user.type = ""
            state.client_user.status = ""
            state.client_user.marketing_kakao = ""
            state.client_user.marketing_sms = ""
            state.client_user.marketing_app = ""
            state.client_user.created = ""
        },

        // 관리자 로그인
        admin_login: (state) => {
            state.client_admin.is_logined = true
        },

        // 관리자 로그아웃
        admin_logout: (state) => {
            state.client_admin.is_logined = false
        },

        // 마이페이지탭 수정
        update_mypage_tab: (state, payload) => {
            state.mypage_tab = payload
        },

        // 소수점 수정
        update_sosujum: (state, payload) => {
            state.sosujum = payload
        },

        // 목록탭 수정
        update_list_tab: (state, payload) => {
            state.list_tab = payload
        },

        // 목록탭 수정
        update_screen_mode: (state, payload) => {
            state.screen_mode = payload
        },

        // 목록탭 수정
        update_keypad_mode: (state, payload) => {
            state.keypad_mode = payload
        },

        update_rotate: (state, payload) => {
            state.rotate = payload
            state.twin = false
        },

        update_twin: (state, payload) => {
            state.twin = payload
            state.rotate = false
        },

        reset_result: (state) => {
            state.result = 0
        },

        update_sub_tab_by_header: (state, payload) => {
            state.sub_tab_by_header = payload
        },

        update_language: (state, payload) => {
            state.language = payload

            // if(payload == '한국어'){
            //     state.sub_tab = '만억조'
            //     state.input_tab = '만억조'
            //     state.sub_tab_by_header = '만억조'
            // }

            // else if(payload == 'English'){
            //     state.sub_tab = 'KMB'
            //     state.input_tab = 'KMB'
            //     state.sub_tab_by_header = 'KMB'
            // }
        },

        update_summary_amount: (state, payload) => {
            state.summary_amount = payload
        },

        update_little_menu: (state, payload) => {
            state.little_menu = payload
        },

        // # list 초기화를 위한 새로운 mutation 추가
        reset_list: (state) => {
            state.list = []
        }
    },

    actions: {
        // // # list 자동 초기화를 위한 action 추가
        // initializeListTimer: ({ commit }) => {
        //     setTimeout(() => {
        //         commit('reset_list')
        //     }, 3600000) // 1시간 = 3600000 밀리초
        //     // }, 10000) // 10초 = 10000 밀리초
        // }
    },

    modules: {

    },

    plugins: [
        createPersistedState({
            paths: [
                'main_tab',
                'client_user',
                'client_admin',
                'sosujum',
                'language',
                'summary_amount',
                'exchange_list',
                'list'
            ]
        }),
        
        // // # list를 위한 커스텀 플러그인 추가
        // store => {
        //     store.subscribe((mutation, state) => {
        //         if (mutation.type === 'update_list') {
        //             store.dispatch('initializeListTimer')
        //         }
        //     })
        // }


        // # list 1시간 제한 둘떄 Home > watch에 추가
        // store의 sub_tab 상태만 감시
        // '$store.state.list': {
        //     handler(newValue, oldValue) {
        //         if(newValue.length == 0){
        //             this.list = []
        //         }
        //     },
        //     immediate: true
        // },

        // store의 list 상태 감시
        // '$store.state.list': {
        //     handler(newValue) {
        //         this.$nextTick(() => {
        //             const currentTime = new Date().getTime()
        //             const lastUpdateTime = parseInt(localStorage.getItem('lastListUpdateTime'))
        //             const timeElapsed = currentTime - lastUpdateTime

        //             if (timeElapsed >= 3600000) {
        //                 console.log("1시간이 지나서 초기화합니다")
        //                 this.list = []
        //             }
                    
        //             // if (timeElapsed >= 10000) {
        //             //     console.log("10초가 지나서 초기화합니다")
        //             //     this.list = []
        //             // }
        //         })
        //     },
        //     immediate: true,
        //     deep: true
        // },
    ]
})
