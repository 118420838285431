const methods = {
    // # 메인 계산식
    formula_main(number, scale, sub_tab){
        // 숫자 없을 시
        if(!number) return ""

        // 숫자 0이면서 소수점이 없을 시
        if(number == 0 && !number.toString().includes('.')) return 0

        // 실수 + 단위 없음 
        if (number && number.toString().includes('.') && scale == null){
            // 숫자 그대로 표시
            return this.$toComma(number)
        }

        // 숫자 + %
        else if (number && number.toString().endsWith('%')) {
            // 숫자 그대로 표시
            return this.$toComma(number)
        }

        // 콤마 제거 후 bignumber로 변환
        number = number.toString().replace(/,/g, '')
        number = this.$math.bignumber(number)

        // # 서브탭이 "000"인 경우
        if(sub_tab == "000"){
            // 숫자 X 단위 => 계산된 숫자
            return this.$toComma(this.$caculateTo000(number, scale))
        }

        // # 서브탭이 "만억조"인 경우
        else if(sub_tab == "만억조"){
            // 단위 없을 시
            if(scale == null){
                return this.$toComma(this.$caculateNumberToMOJ(number))
            }
            // 단위가 만억조
            else if(["만", "억", "조", "경"].includes(scale)){
                return this.$toComma(parseFloat(number)) + scale
            }
            // 단위가 KMB
            else if(["K", "M", "B", "T"].includes(scale)){
                return this.$toComma(this.$caculateKMBtoMOJ(number, scale))
            }
        }

        // # 서브탭이 "KMB"인 경우
        else if(sub_tab == "KMB"){
            // 단위 없을 시
            if(scale == null){
                return this.$toComma(this.$caculateNumberToKMB(number))
            }
            // 단위가 KMB
            else if(["K", "M", "B", "T"].includes(scale)){
                return this.$toComma(parseFloat(number)) + scale
            }
            // 단위가 만억조
            else if(["만", "억", "조", "경"].includes(scale)){
                return this.$toComma(this.$caculateMOJtoKMB(number, scale))
            }
        }
    },

    // # 서브 계산식
    formula_sub(number, scale, sub_tab, input_tab){
        // 숫자 없을 시
        if(!number) return ""

        // 숫자 + %
        if (number.toString().endsWith('%')) return ""
        
        // 서브탭이 "만억조" or "KMB" 일 시
        if(sub_tab == "만억조" || sub_tab == "KMB") return ""

        // 콤마 제거 후 bignumber로 변환
        let bignumber = number.toString().replace(/,/g, '')
        bignumber = this.$math.bignumber(bignumber)

        // # 일반 (서브탭이 "000"인 경우)

        // # 입력탭이 만억조
        if(input_tab == "만억조"){
            // 단위 없을 시
            if(scale == null){
                // 숫자가 만보다 작으면 표시안함
                if(this.$math.compare(bignumber, 10000) < 0){
                    return ''
                }
                // 숫자가 만보다 크면 만억조로 표시
                else{
                    return this.$toComma(this.$caculateNumberToMOJ(bignumber))
                }
            }

            // 단위가 만억조
            else if(["만", "억", "조", "경"].includes(scale)){
                // 숫자 + 단위로 표시
                return this.$toComma(parseFloat(bignumber)) + scale
            }

            // 단위가 KMB
            else if(["K", "M", "B", "T"].includes(scale)){
                // 숫자 + 단위(KMB) => 계산된 숫자 + 단위(만억조)
                return this.$toComma(this.$caculateKMBtoMOJ(bignumber, scale))
            }
        }

        // # 입력탭이 KMB
        else if(input_tab == "KMB"){
            // 단위 없을 시 
            if(scale == null){
                // 숫자가 K보다 작으면 표시안함
                if(this.$math.compare(bignumber, 1000) < 0){
                    return ''
                }
                // 숫자가 K보다 크면 KMB로 표시
                else{
                    return this.$toComma(this.$caculateNumberToKMB(bignumber))
                }
            }

            // 단위가 KMB
            else if(["K", "M", "B", "T"].includes(scale)){
                // 숫자 + 단위로 표시
                return this.$toComma(parseFloat(bignumber)) + scale
            }

            // 단위가 만억조
            else if(["만", "억", "조", "경"].includes(scale)){
                // 숫자 + 단위(만억조) => 계산된 숫자 + 단위(KMB)
                return this.$toComma(this.$caculateMOJtoKMB(bignumber, scale))
            }
        }
    },




    // # 메인 계산식
    formula_main2(number, exchange, scale, show_tab){
        // 숫자 없을 시
        if(!number) return ""

        // 숫자 + %
        else if (number && number.toString().endsWith('%')) {
            // 숫자 그대로 표시
            return this.$toComma(number)
        }

        // 콤마 제거 후 bignumber로 변환
        number = number.toString().replace(/,/g, '')
        number = this.$math.bignumber(number)
        number = this.$math.multiply(number, this.$math.bignumber(exchange))

        let result
        result = this.$math.number(this.$caculateTo000(number, scale))

        // 서브탭이 "000"인 경우
        if(this.$store.state.sub_tab == "000"){
            return result.toLocaleString('en', {maximumFractionDigits: this.$store.state.sosujum})
        }

        // 서브탭이 "만억조"인 경우
        else if(this.$store.state.sub_tab != "000" && show_tab == "만억조"){
            return this.$toCommaWithSosujum(this.$caculateNumberToMOJ(result))
        }

        // 서브탭이 "KMB"인 경우
        else if(this.$store.state.sub_tab != "000" && show_tab == "KMB"){
            return this.$toCommaWithSosujum(this.$caculateNumberToKMB(result))
        }
    },

    // # 서브 계산식
    formula_sub2(number, exchange, scale, show_tab){
        // 숫자 없을 시
        if(!number) return ""

        // 숫자 + %
        if (number.toString().endsWith('%')) return ""
        
        // 콤마 제거 후 bignumber로 변환
        number = number.toString().replace(/,/g, '')
        number = this.$math.bignumber(number)
        number = this.$math.multiply(number, this.$math.bignumber(exchange))

        let result
        result = this.$math.number(this.$caculateTo000(number, scale))

        // # 서브탭이 "만억조" or "KMB" 일 시
        if(this.$store.state.sub_tab == "000"){
            // 서브탭이 "만억조"인 경우
            if(show_tab == "만억조"){
                if(result >= 10000){
                    return this.$toCommaWithSosujum(this.$caculateNumberToMOJ(result))
                }
                else{
                    return ""
                }
            }

            // 서브탭이 "KMB"인 경우
            else if(show_tab == "KMB"){
                if(result >= 1000){
                    return this.$toCommaWithSosujum(this.$caculateNumberToKMB(result))
                }
                else{
                    return ""
                }
            }
        }
    },
}

export default methods